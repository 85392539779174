<template>
  <div>
    <com-popup :showClose="true" mode="top" bodyStyle="width: 100%;height: 100%;border-radius:0;overflow-y: auto;"
               :showFooter="false" :title="LoginType === 'register'?'注册':'登录'" :hidden="!showLogin"
               @close="$store.dispatch('showLogin',false)">
      <div class="login-container"
           :style="'min-height: calc(100vh - 80px);min-height: '+(LoginType === 'register'?' 824px':'455px')+';'">
        <Spin fix v-if="isLoading">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>Loading</div>
        </Spin>
        <img src="@/assets/imgs/logo.jpg"
             style="max-width: 100%;height: 50px;margin: 0px auto 20px;border-radius: 5px;"/>

        <Form :model="formData" label-position="top">
          <template v-if="LoginType === 'register'">
            <FormItem label="手机号">
              <Input name="phone" clearable v-model="formData.phone" placeholder="请输入手机号"></Input>
            </FormItem>
            <FormItem label="密码">
              <Input type="password" name="passwd" clearable password v-model="formData.passwd"
                     placeholder="请输入6-18位密码"></Input>
            </FormItem>
            <FormItem label="确认密码">
              <Input type="password" name="confirm_passwd" clearable password v-model="formData.confirm_passwd"
                     placeholder="请输入6-18位密码"></Input>
            </FormItem>
            <FormItem label="验证码">
              <Input name="passwd" clearable v-model="formData.code" placeholder="验证码">
                <div :style="'line-height: 36px;padding:0 5px;color:'+(CodeEnterButton?'#737f8a':'#2c3e50')"
                     slot="suffix" @click="getCode">{{ CodeEnterButton || '获取验证码' }}
                </div>
              </Input>
            </FormItem>
            <div class="login-button" @click="doRegister">注册并登录</div>
          </template>
          <template v-else>
            <FormItem label="账号">
              <Input name="user_name" clearable v-model="formData.user_name" placeholder="请输入账号"></Input>
            </FormItem>
            <FormItem label="密码">
              <Input type="password" name="passwd" clearable password v-model="formData.passwd"
                     placeholder="请输入密码"></Input>
            </FormItem>
            <div class="login-button" @click="doLogin">登录</div>
          </template>
        </Form>
        <p style="text-align:left;margin-top:20px;" v-if="systemConfig.agreement">
          登录即同意
          <template v-for="(agreement,aIndex) in systemConfig.agreement">
            <span style="color:#f87706;cursor: pointer;"
                  @click="agreementCat(agreement.url , agreement.title)">“{{ agreement.title }}”</span>
            {{ systemConfig.agreement.length - 2 === aIndex ? '和' : (aIndex > 1 ? '、' : '') }}
          </template>
        </p>
        <div style="position: absolute;bottom: 0;right: 0;left: 0;" v-if="!isKeyboardShow">
          <img src="@/assets/imgs/tex.png" style="max-width:100%;max-height:100px;margin:0px auto 20px;display: block;"
               v-if="LoginType === 'register'"/>
          <p>如没有账号，请点击用户注册按钮注册平台账号</p>
          <div style="display: flex;align-items: center;justify-content: center;color:#000">
            <div @click="$store.dispatch('showLogin','')">
              <img src="@/assets/imgs/zhanghao.png" style="max-width:100%;max-height:30px;margin:10px auto 0;"/>
              <p>账号登录</p>
            </div>
            <div @click="$store.dispatch('showLogin','register')" style="margin-left: 20px;">
              <img src="@/assets/imgs/youkedenglu.png" style="max-width:100%;max-height:30px;margin:10px auto 0;"/>
              <p>用户注册</p>
            </div>
          </div>
        </div>
      </div>
    </com-popup>
    <com-popup :showClose="true" bodyStyle="height: 70vh;" :title="title" :hidden="webviewSrc?false:true"
               @close="webviewSrc = '';" :showFooter="false">
      <iframe :src="webviewSrc"></iframe>
    </com-popup>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState(['showLogin', 'LoginType'])
  },
  data() {
    return {
      LoginTitle: "",
      title: "",
      formData: {
        phone: '',
        passwd: '',
        sessionid: '',
        code: '',
        group_buy_uuid: '',
      },
      isLoading: false,
      webviewSrc: '',
      CodeEnterButton: '',
      CodeEnterIndex: 0,
    }
  },
  watch: {
    showLogin(v) {
      if (v) {
        this.title = '';
        this.webviewSrc = '';
      }
    },
  },
  created() {
    this.$nextTick(() => {
      if (!this.userInfo.userId) {
        var token = this.$util.localStorage("authToken", '');
        !token && this.$store.dispatch('showLogin', true);
      }
    })
    this.$router.onReady(() => {
      let data = this.$route.query; // John
      console.log(data);
      if (Object.keys(data).length !== 0) {
        // console.log(data.uid, data.t, data.sign, "325423523");
        if (data.token === undefined || data.token === "") {
          if (data.uid === undefined || data.t === undefined || data.sign === undefined) {
            return;
          }
        }
        this.$http.post('/api/freeLogin' , data).then(({data})=>{
          if(data.code == 200){
            this.loginSuccess(data.data);
          }else{
            this.$Message.error(data.msg || '账号登录失败，请重试！')
          }
        })
      }
    });
  },
  mounted() {
    let {group_uuid} = this.$route.query;
    if (group_uuid) this.$util.localStorage('group_uuid', group_uuid);
    this.formData.group_buy_uuid = this.$util.localStorage('group_uuid', '');
  },
  methods: {
    agreementCat(url, title) {
      this.title = title;
      this.webviewSrc = url;
    },
    doLogin() {
      if (this.isLoading) return;
      let {user_name, passwd, group_buy_uuid} = this.formData;
      this.isLoading = true;
      this.$http.post('/api/login', {user_name, passwd, group_buy_uuid}).then(({data}) => {
        if (data.code == 200) {
          this.loginSuccess(data.data);
        } else {
          this.$Message.error(data.msg || '账号登录失败，请重试！')
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
    getCode() {
      let {phone} = this.formData;
      if (!phone) return this.$Message.error('请输入手机号！');
      var isClick = this.CodeEnterButton === '';
      if (isClick) {
        this.$http.get('/api/mobile/code', {phone}).then(({data}) => {
          if (data.code == 200) {
            this.formData.sessionid = data.data.data;
            var t = 60;
            this.CodeEnterIndex = setInterval(() => {
              if (t === 0) {
                clearInterval(this.CodeEnterIndex);
                this.CodeEnterIndex = 0;
                this.CodeEnterButton = ''
              } else {
                this.CodeEnterButton = t + '秒';
              }
              t--;
            }, 1000);
          } else {
            this.$Message.error(data.msg || '账号登录失败，请重试！')
          }
        });
      }
    },
    doRegister() {
      let {phone, passwd, confirm_passwd, sessionid, code, group_buy_uuid} = this.formData;
      if ((passwd || confirm_passwd) && passwd !== confirm_passwd) return this.$Message.error('确认密码不正确，请重新输入');
      this.$http.post('/api/register', {phone, passwd, sessionid, code, group_buy_uuid}).then(({data}) => {
        if (data.code == 200) {
          this.loginSuccess(data.data);
        } else {
          this.$Message.error(data.msg || '账号注册失败，请重试！')
        }
      });
    },
    loginSuccess(authToken) {
      this.$Message.success('登录成功！');
      this.$store.dispatch('setUserInfo', {authToken});
      this.$util.localStorage('group_uuid', null);
      this.$emit("loginSuccess");
      this.$store.dispatch('showLogin', false);
    },
  },
}
</script>


<style lang="less">
.login-container {
  text-align: center;
  color: #999;
  padding: 0 20px;
  position: relative;
  width: 100%;
  height: 100%;

  .login-items {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #dcdcdc;
  }

  .login-button {
    background: #f87706;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    border-radius: 20px;
  }

  .ivu-form .ivu-form-item-label {
    float: left;
    // text-align: justify;
    // text-align-last: justify;
    color: #999;
  }

  .ivu-input {
    color: #999;
  }

  input:-internal-autofill-selected {
    background-color: rgb(232, 240, 254 / 70%) !important;
  }
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
  overflow: auto;
}

/* 隐藏滚动条 */
iframe::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}
</style>
